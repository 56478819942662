import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgControl, NgSelectOption, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { SidebarBlockComponent } from './layout/sidebar/components/sidebar-block/sidebar-block.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import {
    ArrowDownOutline,
    ArrowRightOutline,
    ArrowUpOutline,
    BugOutline,
    DesktopOutline,
    DownOutline,
    FlagOutline,
    FundOutline,
    HddOutline,
    InfoCircleOutline,
    LeftOutline,
    NotificationOutline,
    RightOutline,
    SearchOutline,
    SettingOutline,
    SnippetsOutline,
    UserOutline,
} from '@ant-design/icons-angular/icons';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { UserProfileComponent } from './layout/sidebar/components/user-profile/user-profile.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { GeneralSearchComponent } from './layout/sidebar/components/general-search/general-search.component';
import { SidebarSectionComponent } from './layout/sidebar/components/sidebar-section/sidebar-section.component';
import { SidebarTopbarComponent } from './layout/sidebar/components/sidebar-topbar/sidebar-topbar.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TopNavigationComponent } from './layout/top-navigation/top-navigation.component';
import { SecondarySidebarComponent } from './layout/secondary-sidebar/secondary-sidebar.component';
import { NzSelectModule } from 'ng-zorro-antd/select';

const icons = [
    SearchOutline,
    HddOutline,
    BugOutline,
    FundOutline,
    SnippetsOutline,
    UserOutline,
    DownOutline,
    DesktopOutline,
    NotificationOutline,
    InfoCircleOutline,
    SettingOutline,
    LeftOutline,
    RightOutline,
    FlagOutline,
    ArrowUpOutline,
    ArrowDownOutline,
    ArrowRightOutline,
];

@NgModule({
    declarations: [
        NotFoundPageComponent,
        SidebarComponent,
        SidebarBlockComponent,
        UserProfileComponent,
        GeneralSearchComponent,
        SidebarSectionComponent,
        SidebarSectionComponent,
        SidebarTopbarComponent,
        TopNavigationComponent,
        SecondarySidebarComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterLink,
        RouterLinkActive,
        TranslateModule.forChild({}),
        TranslatePipe,

        // ng zorro modules
        NzLayoutModule,
        NzInputModule,
        NzIconModule,
        NzButtonModule,
        NzDropDownModule,
        NzIconModule.forRoot([]),
        NzSelectModule,
        FormsModule,
    ],
    exports: [SidebarComponent, TranslateModule, TopNavigationComponent],
    providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class CoreModule {}
