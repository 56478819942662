import { inject, Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModuleItem } from '../layout/sidebar/types';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TenantModules } from '../layout/sidebar/module-list';

@Injectable({
    providedIn: 'root',
})
export class SidebarStateService {
    private router = inject(Router);
    private sidebarExpandedSubject = new BehaviorSubject<boolean>(false);
    private moduleDataSubject = new BehaviorSubject<ModuleItem | null>(null);

    public isExpanded$: Observable<boolean> = this.sidebarExpandedSubject.asObservable();
    public moduleData$: Observable<ModuleItem | null> = this.moduleDataSubject.asObservable();

    constructor() {
        this.initializeModuleDataSubscription();
    }
    get isExpanded() {
        return this.sidebarExpandedSubject.getValue();
    }

    toggleSidebarState(): void {
        this.sidebarExpandedSubject.next(!this.isExpanded);
    }

    setSidebarState(isExpanded: boolean): void {
        this.sidebarExpandedSubject.next(isExpanded);
    }

    private initializeModuleDataSubscription(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            const fullPath = this.router.url.split('?')[0];
            const currentPath = fullPath.split('/')[1];
            const module = TenantModules.find(module => module.id === currentPath) || null;
            this.moduleDataSubject.next(module);
        });
    }
}
