import { Component, inject } from '@angular/core';
import { SidebarStateService } from '../../services/sidebar.service';

@Component({
    selector: 'app-top-navigation',
    templateUrl: './top-navigation.component.html',
})
export class TopNavigationComponent {
    private sidebarStateService = inject(SidebarStateService);
    public moduleData$ = this.sidebarStateService.moduleData$;
    public isExpanded = this.sidebarStateService.isExpanded;
}
