<ul
    nz-menu
    nzMode="inline"
    class="modules-list flex flex-col gap-1 overflow-x-clip border-x-0 py-6"
    [nzSelectable]="false"
>
    <ng-container *ngFor="let block of moduleList; trackBy: trackByBlockTitle">
        <li class="module-title-container my-3 px-2" *ngIf="!isExpanded">
            <p class="module-title text-black/45">{{ block.blockTitle | translate}}</p>
        </li>
        <ng-container *ngFor="let module of block.modules; trackBy: trackByModuleName">
            <li app-sidebar-block [moduleItem]="module" [isExpanded]="isExpanded"></li>
        </ng-container>
    </ng-container>
</ul>
