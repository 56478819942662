<div class="flex">
    <a
        class="flex w-full items-center gap-3 text-black"
        nz-dropdown
        [nzVisible]="isActive"
        nzTrigger="click"
        (nzVisibleChange)="isActive ? (isActive = false) : (isActive = true)"
        [nzDropdownMenu]="userDropdownOptions"
    >
        <i
            class="box-content inline-flex min-h-8 min-w-8 items-center justify-center rounded-full bg-black/25 text-[1.125rem] text-white"
            nz-icon
            nzType="user"
            nzTheme="outline"
            [ngClass]="isExpanded ? 'mx-2': 'mx-0'"
        ></i>
        <span class="flex w-[85%] gap-3" *ngIf="!isExpanded">
            <span class="truncate"> {{ displayName }}</span>
            <i
                nz-icon
                nzType="down"
                nzTheme="outline"
                class="transition duration-200"
                [ngClass]="{ 'rotate-180': isActive, 'rotate-0': !isActive }"
            ></i>
        </span>
    </a>
</div>

<nz-dropdown-menu #userDropdownOptions>
    <div
        class="mx-3 flex flex-col items-stretch rounded-lg bg-white p-1 shadow-lg *:rounded-lg *:px-3 *:py-1.5 *:text-left *:transition"
    >
        <button nz-button nzType="text" class="flex h-full hover:bg-black/[6%]">
            <a routerLink="/profile" class="flex">{{ 'My profile' | translate }}</a>
        </button>
        <a
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="langMenu"
            class="flex justify-between"
            (nzVisibleChange)="isLangMenuActive ? (isLangMenuActive = false) : (isLangMenuActive = true)"
        >
            {{ 'languageChange' | translate }}
            <i
                nz-icon
                nzType="down"
                nzTheme="outline"
                class="transition duration-200"
                [ngClass]="{ 'rotate-180': isLangMenuActive, 'rotate-0': !isLangMenuActive }"
            ></i>
        </a>

        <button class="text-alert hover:bg-alert hover:text-white" (click)="logout()" (keyup)="logout()">
            {{ 'Sign out' | translate }}
        </button>
    </div>
</nz-dropdown-menu>

<nz-dropdown-menu #langMenu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item (click)="langChange('pl')">{{ 'polish' | translate }}</li>
        <li nz-menu-item (click)="langChange('en')">{{ 'english' | translate }}</li>
    </ul>
</nz-dropdown-menu>
