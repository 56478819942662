import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnDestroy {
    @Input() isExpanded: boolean;
    @Input() displayName: string;
    public isActive: boolean = false;
    public isLangMenuActive: boolean = false;

    private readonly authService = inject(AuthService);
    private readonly translateService = inject(TranslateService);
    private logoutSubscription: Subscription;

    logout() {
        this.logoutSubscription = this.authService.logout().subscribe();
    }

    langChange(lang: string) {    
        localStorage.setItem('lang', lang);
        this.translateService.use(lang);
        this.isActive = false;
        this.isLangMenuActive = false;
    }

    ngOnDestroy(): void {
        if (this.logoutSubscription) {
            this.logoutSubscription.unsubscribe();
        }
    }
}
