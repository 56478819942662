import { ModuleItem, ModuleList } from './types';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

export const TenantModules: ModuleItem[] = [
    {
        id: 'aimarketing',
        name: _('Ai Marketing'),
        href: '/aimarketing/sales-history',
        isFavorite: false,
        icon: 'hdd',
        direction: 'vertical',
        submodules: [
            {
                title: _('AiMarketing.salesHistory'),
                href: '/aimarketing/sales-history',
            },
            {
                title: _('AiMarketing.budgetSummary'),
                href: '/aimarketing/budget-summary',
            },
            {
                title: _('AiMarketing.expanditureAnalysis'),
                href: '/aimarketing/expanditure-analysis',
            },
            {
                title: _('AiMarketing.historicalData'),
                href: '/aimarketing/historical-data',
            },
        ],
    },
    {
        id: 'rfx',
        name: _('AI RFX Automation'),
        href: '/rfx',
        isFavorite: false,
        icon: 'bug',
        direction: 'horizontal',
        submodules: [
            {
                title: 'RFX List',
                href: '/rfx',
                params: {
                    sortDirection: 'DESC',
                    sortBy: 'createdAt',
                },
            },
        ],
    },
    {
        id: 'demandforecasting',
        name: _('AI Demand  Forecasting'),
        href: '/demandforecasting/planning',
        isFavorite: true,
        icon: 'fund',
        direction: 'horizontal',
        submodules: [
            {
                title: _('DemandForecasting.planningMenu'),
                href: '/demandforecasting/planning',
            },
            {
                title: _('DemandForecasting.KPIMenu'),
                href: '/demandforecasting/kpi',
            }, 
            {
                title: _('DemandForecasting.productMenu'),
                href: '/demandforecasting/products',
            },
            {
                title: _('DemandForecasting.clientsMenu'),
                href: '/demandforecasting/clients',
            },
            {
                title: _('DemandForecasting.forecastMenu'),
                href: '/demandforecasting/forecast',
            },
            {
                title: _('DemandForecasting.dataMenu'),
                href: '/demandforecasting/data-upload',
            },
        ],
    },
    // TODO: add translations for the submodules
    {
        id: 'aicontract',
        name: _('AI Contract Intelligence'),
        href: '/aicontract',
        isFavorite: false,
        icon: 'bug',
        direction: 'horizontal',
        submodules: [
            {
                title: 'Documents',
                href: '/aicontract/documents',
            },
            {
                title: 'Search',
                href: '/aicontract/search',
            },
            {
                title: 'Dashboards',
                href: '/aicontract/dashboards',
            },
            {
                title: 'Contract AI',
                href: '/aicontract/contractai',
            },
        ],
    },
];

export const BaseModuleList: ModuleList[] = [
    {
        blockTitle: _('General'),
        modules: [
            {
                name: _('Main Board'),
                href: '/dashboard',
                icon: 'desktop',
            },
            {
                name: _('Notifications'),
                href: '/notifcations',
                icon: 'notification',
            },
        ],
    },
    {
        blockTitle: _('Modules'),
        modules: [...TenantModules],
    },
    // {
    //     blockTitle: 'Ulubione',
    //     modules: [...AllModules.filter(module => module.isFavorite)],
    // },
];

export const SupportAndSettingsModuleList: ModuleList[] = [
    {
        blockTitle: _('Administration'),
        modules: [
            {
                name: _('Support'),
                href: '/support',
                icon: 'info-circle',
            },
            {
                name: _('Settings'),
                href: '/settings',
                icon: 'setting',
            },
        ],
    },
];
export const LanguageSwitcherModuleItem: ModuleItem = {
    name: 'LanguageSwitcher',
    icon: 'flag',
};
