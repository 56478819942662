<div class="flex h-full bg-white">
    <nz-layout
        class="sidebar bg-white transition-[width] duration-200"
        [class.w-[316px]]="!isExpanded"
        [class.w-[79px]]="isExpanded"
    >
        <nz-sider
            class="relative flex h-full flex-col justify-between border-r"
            [ngClass]="isExpanded ? 'px-4' : 'px-6'"
            nzCollapsible
            nzTheme="light"
            [nzWidth]="316"
            [nzCollapsedWidth]="79"
            [(nzCollapsed)]="isExpanded"
            (nzCollapsedChange)="onCollapsedChange($event)"
            [nzTrigger]="stateToggleTrigger"
        >
            <div class="main-content flex h-full flex-col justify-between">
                <div>
                    <app-sidebar-topbar [isExpanded]="isExpanded" />
                    <app-sidebar-section [moduleList]="availableModuleList" [isExpanded]="isExpanded" />
                </div>
                <div>
                    <app-user-profile [isExpanded]="isExpanded" [displayName]="displayName" />
                    <app-sidebar-section [moduleList]="supportAndSettingsModuleList" [isExpanded]="isExpanded" />
                </div>
            </div>
            <ng-template #stateToggleTrigger>
                <div class="sidebar-state-toggle !py-2" *ngIf="!isExpanded">
                    <button
                        class="btn-hide flex w-full items-center justify-center overflow-clip rounded-lg"
                        nz-button
                        nzType="default"
                    >
                        <span nz-icon nzType="left" nzTheme="outline"></span> {{ !isExpanded ? ('Sidebar.hideMenu' |
                        translate) : null }}
                    </button>
                </div>
                <div class="px-3 pt-2" *ngIf="isExpanded">
                    <button
                        class="btn-hide flex w-full items-center justify-center overflow-clip rounded-lg"
                        nz-button
                        nzType="default"
                    >
                        <span nz-icon nzType="right" nzTheme="outline"></span>
                    </button>
                </div>
            </ng-template>
        </nz-sider>
    </nz-layout>
    <app-secondary-sidebar></app-secondary-sidebar>
</div>
