<ng-container *ngIf=" moduleData$ | async as moduleData">
    <header
        *ngIf="moduleData.direction === 'horizontal'"
        class="flex min-h-[3.5rem] w-full items-center justify-between bg-white px-6 py-3"
    >
        <div class="module-details">
            <p class="flex items-center gap-2">
                <span
                    class="flex w-fit rounded-full bg-primary-green/10 p-1.5 text-primary-green empty:hidden"
                    nz-icon
                    [nzType]="moduleData?.icon"
                ></span>
                <span class="text-bd-sm-700">{{moduleData?.name || 'Alternative text'}}</span>
            </p>
        </div>
        <div>
            <ul class="module-navigation-links flex items-center">
                @for(submodule of moduleData?.submodules; track submodule) {
                <li>
                    <a
                        [routerLink]="submodule.href"
                        [queryParams]="submodule.params"
                        [routerLinkActive]="['navigation-item-active']"
                        class="navigation-item"
                        >{{submodule?.title | translate}}</a
                    >
                </li>
                }
            </ul>
        </div>
    </header>
</ng-container>
