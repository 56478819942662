@if (!isExpanded) {
<div class="search-expanded my-1.5 py-4">
    <nz-input-group class="rounded-lg" nzSearch [nzAddOnAfter]="suffixIconButton">
        <input type="text" nz-input [placeholder]="'Search' | translate" class="rounded-lg" />
        <ng-template #suffixIconButton>
            <button nz-button nzType="default" class="group rounded-l-none !rounded-ee-lg !rounded-tr-lg">
                <span nz-icon nzType="search"></span>
            </button>
        </ng-template>
    </nz-input-group>
</div>
} @else {
<div class="search-collapsed !my-1.5 mx-auto flex w-full justify-center !p-4">
    <button
        class="btn-search mx-auto inline-flex items-center justify-center"
        nz-button
        nzType="primary"
        nzShape="circle"
        (click)="search()"
    >
        <span nz-icon nzType="search" class="size-4 text-white"></span>
    </button>
</div>
}
