import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BaseModuleList, SupportAndSettingsModuleList } from './module-list';
import { selectAuthState } from '../../state/auth.selectors';
import { AuthState } from '../../state/auth.reducers';
import { SidebarStateService } from '../../services/sidebar.service';

/**
 * Main sidebar component.
 */
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    private readonly store$ = inject(Store<AppState>);
    private readonly cdr = inject(ChangeDetectorRef);
    private sidebarStateService = inject(SidebarStateService);

    public availableModuleList = [];
    public displayName: string = null;
    public supportAndSettingsModuleList = SupportAndSettingsModuleList;
    public isExpanded: boolean = this.sidebarStateService.isExpanded;

    ngOnInit(): void {
        this.subscriptions.add(
            this.store$.select(selectAuthState).subscribe(state => {
                if (state?.user?.displayName) {
                    this.displayName = state?.user?.displayName;
                }
                this.updateMenu(state);
            })
        );
    }
    onCollapsedChange(isCollapsed: boolean): void {
        this.sidebarStateService.setSidebarState(!isCollapsed);
        this.cdr.detectChanges();
    }
    generalSearch() {}

    updateMenu(state: AuthState): void {
        const userModules = state?.user?.modules;
        this.availableModuleList = BaseModuleList.map(block => {
            const filteredModules = block.modules.filter(module => {
                return module.id ? userModules?.includes(module.id) : true;
            });

            // Return a new block with filtered modules
            return {
                blockTitle: block.blockTitle,
                modules: filteredModules,
            };
        });
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
