import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubdomainService } from '../services/subdomain.service';

@Injectable()
export class SubdomainInterceptor implements HttpInterceptor {
    constructor(private subdomainService: SubdomainService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const subdomain = this.subdomainService.getSubdomain();
        // Clone the request to add the new header
        const modifiedReq = req.clone({
            withCredentials: true,
            setHeaders: {
                Subdomain: subdomain || '', // Provide a default value if subdomain is empty
            },
        });

        // Pass the modified request to the next handler
        return next.handle(modifiedReq);
    }
}
